import { useQuery } from "@apollo/client";
import Axios from "axios";
import { AutoScrollToTop } from "components/AutoScrollToTop";
import { ContactUs } from "components/ContactUs";
import { FormFlow } from "components/FormFlow";
import { Assets, assetsStatusNames } from "components/FormModules/Assets";
import Expenses, { expensesStatusNames } from "components/FormModules/Expenses";
import { Income, incomeStatusNames } from "components/FormModules/Income";
import { Legal, legalStatusNames } from "components/FormModules/Legal";
import {
  Liabilities,
  liabilitiesStatusNames,
} from "components/FormModules/Liabilities";
import {
  YourHousehold,
  yourHouseholdStatusNames,
} from "components/FormModules/YourHousehold";
import { Loading } from "components/Loading";
import NewFilingAboutTheProcess from "components/Onboarding/NewFiling/AboutTheProcess";
import NewFilingWelcome from "components/Onboarding/NewFiling/Welcome";
import * as React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import LOAD_PRE_BANKRUPTCY_FORM from "./LoadPreBankruptcyForm.gql";
import { NewFilingDashboard } from "./PreBankruptcyDashboard";
import LawFirmNotice from "components/LawFirmNotice";
import EOLNotice from "components/LawFirmNotice/EOLNotice";

export function PreBankruptcyForm() {
  const history = useHistory();
  const { data } = useQuery<LoadPreBankruptcyForm>(LOAD_PRE_BANKRUPTCY_FORM);

  if (!data) return <Loading />;

  return (
    <AutoScrollToTop>
      <Helmet>
        <title>Your application</title>
      </Helmet>

      <ContactUs />

      <LawFirmNotice />

      <EOLNotice />

      <FormFlow
        onSubmit={async () => {
          const { status } = await Axios.post("/api/v1/new_filing/review/");
          if (status === 200) {
            history.push("/new-filing-review");
          }
        }}
        AboutTheProcess={NewFilingAboutTheProcess}
        Dashboard={NewFilingDashboard}
        Welcome={NewFilingWelcome}
        showWelcome={!data.filingProfile.hasCompletedNewFilingWelcome}
        steps={[
          {
            title: "About You",
            component: YourHousehold,
            statusNames: yourHouseholdStatusNames,
            props: { subMenuContainerID: "about-you-nav-link" },
          },
          {
            title: "Assets",
            component: Assets,
            statusNames: assetsStatusNames,
            props: { subMenuContainerID: "assets-nav-link" },
          },
          {
            title: "Liabilities",
            component: Liabilities,
            statusNames: liabilitiesStatusNames,
            props: { subMenuContainerID: "liabilities-nav-link" },
          },
          {
            title: "Income",
            component: Income,
            statusNames: incomeStatusNames,
            props: { subMenuContainerID: "income-nav-link" },
          },
          {
            title: "Expenses",
            component: Expenses,
            statusNames: expensesStatusNames,
            props: { subMenuContainerID: "expenses-nav-link" },
          },
          {
            title: "Legal Matters",
            component: Legal,
            statusNames: legalStatusNames,
            props: { subMenuContainerID: "legal-matters-nav-link" },
          },
          // If you're suspiciously not getting the submenu to render and you have spaces in the name, look at legal matters as an example
        ]}
      />
    </AutoScrollToTop>
  );
}
