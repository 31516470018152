import { useGetSession } from "api/graphql/Session";
import { Modal } from "@mantine/core";

export default function EOLNotice() {
  const { data } = useGetSession();
  const lawFirmShortName = data?.session?.user?.referralFirm?.shortName;

  // If the current date is before Jan 1 2025, just return null
  // If the current date is after Jan 1 2025, return the EOL notice

  if (new Date() < new Date("2025-01-01")) {
    return null;
  }

  return (
    <>
      {lawFirmShortName && lawFirmShortName == "MillerMillerLaw" && (
        <>
          <Modal
            opened={true}
            title=""
            withCloseButton={false}
            size={"lg"}
            padding={"lg"}
            onClose={() => {
              return null;
            }}
            centered
          >
            <div>
              Effective January 1, 2025, Miller & Miller clients no longer have
              access to the Lexria software. <br />
              <br />
              Please get in touch with Ari Griffin (
              <a href="mailto:ari@millermillerlaw.com">
                ari@millermillerlaw.com
              </a>
              ) for further instruction on using the new client portal.
            </div>
          </Modal>
        </>
      )}
    </>
  );
}
